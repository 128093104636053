.spinner {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    animation: spinner 1s ease infinite;
  }
    
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
    