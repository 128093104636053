.button {
    --background: var(--primary-color);
    width: 100%;
    max-width: 370px;
    font-size: .9em;
    height: 50px;
    margin-bottom: 1rem;
    margin:auto;
    margin-top: 16px;
    font-weight: 600;
    text-transform: none !important;
}

.cancel {
    --background: #ef345f;
}