.AMalertContainerMobile{
    position: absolute;
    top: 0em;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    background: #00000078;
    left: 0px;
}

.AMalertContainerDesktop{
    position: absolute;
    top: 0em;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 9999;
    background: #00000078;
    left: 0px;
    padding-right: 18vw;
}

.AMalertContainerIframe{
    position: fixed;
    top: 0em;
    height: 570px;
    width: 100vw;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 9999;
    background: #00000078;
    left: 0px;
    padding-right: 18vw;
}

@media only screen and (hover: none) and (pointer: coarse){
    .AMalertContainer{
        top: 1em;
    }
}

.AMalertMobile{
    width: 80%;
    padding: 10px;
    border-radius: 15px;
    background: white;
}

.AMalertMobile > h1 {
    color: #4c4c4c;
    margin: 5px 0px 5px 0px;
    font-size: 1.5em;
}


.AMalertMobile > p {
    font-size: 0.9em;
    color: gray;
    text-align: center;
}

.AMalertMobile > img {
    margin: auto;
    display: flex
}


.AMalertIconMobile {
    margin: auto;
    display: flex;
    justify-content: center;
}
.AMalertDesktop {
    width: 30%;
    padding: 10px;
    border-radius: 15px;
    background: white;
}

.AMalertDesktop > h1 {
    color: #4c4c4c;
    margin: 5px 0px 5px 0px;
    font-size: 1.5em;
}


.AMalertDesktop > p {
    font-size: 0.9em;
    color: gray;
    text-align: center;
}

.AMalertDesktop > img {
    margin: auto;
    display: flex
}

.AMalertIconDesktop {
    margin: auto;
    display: flex;
    justify-content: center;
}

.AMalertButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.AMalertIframe {
    width: 90%;
    padding: 10px;
    border-radius: 15px;
    background: white;
    margin: auto;
  
}

.AMalertIframe > h1 {
    color: #4c4c4c;
    margin: 5px 0px 5px 0px;
    font-size: 1.5em;
}


.AMalertIframe > p {
    font-size: 0.9em;
    color: gray;
    text-align: center;
}

.AMalertIframe > img {
    margin: auto;
    display: flex
}


.AMalertButtons > button{
    background-color: var(--primary-color);
    color: white;
    width: 40%;
    height: 40px;
    border-radius: 10px;
}
