/* From Adaboh's project */
#videoElement {
    z-index:0;
}

.person{
    background-color: transparent;
    z-index: 9999;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    -moz-transform: scale(-1, 1);
    -webkit-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    transform: scale(-1, 1);
    filter: FlipH;
}

.croma-background {
    position: absolute;
    width: 100%;
    height: 100vh;
}

.croma-background img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
}

.croma-background video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: absolute;
}