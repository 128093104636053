.amChatContainerMobile{
    width: 105%;
    bottom: 75px;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 50%;
    left: -15px;
}

.amChatContainerDesktop{
    position: absolute !important;
    width: 420px;
    margin: auto;
    height: 65vh;
    padding-top: 15px;
    display: flex;
    justify-content: flex-end;
    bottom: 50px;
}
.amChatContainerIframe{
    width: 100%;
    bottom: -150px;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 50%;
   
}


.amChatContainerInnerMobile{
    transition: 0.5s;
    height: 100%;
    width: 80%;
    margin-left: 10% ;
    margin-right: 10%;
}

.amChatContainerInnerIframe{
    transition: 0.5s;
    height: 100%;
    width: 80%;
    margin-right: 10%;
}

.amChatContainerInnerDesktop{
    transition: 0.5s;
    height: 50%;
    width: 89%;
    margin-left: 5.5%;
    margin-right: 5.5%;
    margin-top: 42% !important;
}

.operatorInbox{
    transition: 0.1s;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    min-height: 10px;
    border-radius: 15px;
    margin: 2px 2px 2px 0;
    padding: 5px 10px 5px 10px;
    width: 100%;
    color: black;
    font-size: 0.7em;
}

.clientInbox{
    transition: 0.1s;
    overflow-x: hidden;
    transition: 0.1s;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    min-height: 10px;
    height: auto;
    border-radius: 15px;
    margin: 2px 2px 2px 0;
    padding: 5px 10px 5px 10px;
    width: 100%;
    color: black;
    font-size: 0.7em;
    justify-content: flex-end;
}

.operatorInboxContainer{
    display: flex;
    margin: 5px 2.5px 5px 0px;
    align-items: center;
    background-color: #ffffff8f;
    border-radius: 5px;
    padding: 5px ;
}

.clientInboxContainer{
    display: flex;
    margin: 5px 2.5px 5px 0px;
    align-items: center;
    background-color: #ffffff8f;
    border-radius: 5px;
    padding: 5px ;
}


.avatar{
    border-radius: 50%;
    color: white;
    height: 25px;
    width: 25px;
    min-width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    background-color: rgb(243, 114, 44);
    
}
.avatarName {
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 18px;
}

.avatarNameOperator {
    height: 15px;
    width: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
}

.opAvatar{
    height: 25px;
    width: 25px;
    background: var(--header-img);
    background-size: contain;
    background-repeat: no-repeat;
}

.amSenderContainer{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
}

.amInputSenderAllowFile{
    border: none !important;
    color: black !important;
    width: 75% !important;
   /*  height: 30px !important; */
    border-radius: 5px;
    background-color: #ffffff !important;
    font-size: 0.8em!important;
    font-weight: 400!important;
    padding-left: 10px !important;
    text-align: left!important;
    margin-right: 10px;
}

.amInputSender{
    border: none !important;
    color: black !important;
    width: 85% !important;
   /*  height: 30px !important; */
    border-radius: 5px;
    background-color: #ffffff !important;
    font-size: 0.8em!important;
    font-weight: 400!important;
    padding-left: 10px !important;
    text-align: left!important;
    margin-right: 10px;
}


.amInputSender::placeholder{
    color: black !important;
    font-size: 13px;
    margin: auto;
}

.amInputSender:focus{
    border: none !important;
    outline: none !important;
}

.amInboxScroll{
    height: 70%;
    min-height: 70%;
    margin-bottom: 10%;
    overflow: scroll;
    overflow: auto; /*Graceful degradation para Firefox*/
    overflow: overlay;
}

.amInboxScroll::-webkit-scrollbar{
    display: none;
}

.hideChat{
    background-color: rgba(12, 12, 12, 0.39);
    height: 10%;
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px 15px 0px 0px;
    margin-left: 42%;
    transition: 0.5s;
    position: absolute;
}

.amChatClose{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.chatLink{
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
}
.iconChatContainerDesktop{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: var(--primary-color);
    display: grid;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    padding-top: 5px;
}

.iconChatContainerIframe{
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: var(--primary-color);
    display: grid;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.iconChatContainerDesktop img {
    width: 15px;
    height: 15px;
}
.iconChatContainerMobile{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: var(--primary-color);
    display: grid;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}

.iconChatContainerMobile img {
    width: 25px;
    height: 25px;
}