.footer {
    min-height: 4vh;
}

.footerImage {
    width: 90px;
    display: inline-block;
}

.footerDown{
    bottom: 0px;
    position: sticky;
}

.footerFree{
    bottom: 0px;
    display: flex;
    justify-content: space-around;
    background-color: white;
    color: lightgray;
}

.footerText{
    font-size: 0.6rem;
    height: auto;
    background-color: white;
    display: flex;
    justify-content: space-around;
    margin: auto;
    border-top: 1px solid lightgray;
    padding: 10px;
}

.footer-md::before{
    background-image: none;
}