
.selector {
    --padding-top: 17px;
    --padding-bottom: 17px;
    --placeholder-opacity: 0.5;
    --max-width: 90%;
    --width: 30px;
    font-size: 0.8rem;
    text-align: center;
    position: relative;
    order: 0;
    transition: all 0.4s;
    box-sizing: border-box;
    border: 0px;
}

.alert-radio-label.sc-ion-alert-md {
    white-space: initial !important;
    text-overflow: clip !important;
    
}