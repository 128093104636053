.radioItem {
    font-style: var(--secondary-font);
    --background: white;
    border: 1.5px solid rgba(221, 221, 221, 0.612);
    --padding-bottom: -1px;
    --border-radius: 20px;
    --color:  grey;
    margin-bottom: 5px !important;
    border-radius: 5px;
    --border-style:none ;
    font-size: 0.7rem;
}


.title {
    --padding-start: 20px;
    font-weight: bold;
    margin-top: 10px !important;
}

.fontColor {
      font-size: 0.8rem;
    margin-top: 15px;
    margin-bottom: 1.5rem;
    font-weight: 600;
    line-height: 1.5em;
    color: gray
}

.margin {
    margin: 3px;
}
.group{
    border: black 2px;
}