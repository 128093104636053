.headerImage {
  height: 150px;
  width: 150px;
}

.coloredBackgroundDesktop{
  background: var(--primary-color);
  height: 200px;
}

.coloredBackgroundMobile{
  background: var(--primary-color);
  height: 100px;
}

.headerImgDesktop{
  background: var(--header-img);
  background-size: cover;
  background-position: 0 20%;
  height: 200px;
}


.headerImgMobile {
  background: var(--header-img);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100px;
}

.subheader::before {
  z-index: 9999;
  content: "";
  height: 100px;
  width: 100px;
  background: var(--secondary-color) content-box;
  border-radius: 50%;
  background-image: var(--logo);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: absolute;
  padding: 0.5em;
  box-sizing: border-box;
}

.desktop-subheader{
  height: 100px;
  width: 100px;
  background-image: var(--logo);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  box-sizing: border-box;
}
.desktop-subheader-no-logo{
  height: 100px;
  width: 100px;
  box-sizing: border-box;
}

.headerflexCenter {
 display: flex;
 justify-content: center;
 align-items: center;
 margin-top:-60px;
 position: absolute;
 width: 100%;
}

.header-desktop{
  margin: auto;
  width: 70vw;
  display: flex;
  justify-content: space-between;
}

.text-desktop-sub{
  font-size: 0.9em;
  color: rgb(206, 206, 206);

  a{
    color: var(--secondary-color);
  }
}

.text-desktop-imp{
  font-size: 1.7em;
  color: rgb(255, 255, 255);
  margin-top: auto;
  font-weight: bold;
}