.checkbox {
    margin-right: 0px;
    border-width: 1px;
    --border-radius: 5px;
    --background: white;
    --background-checked: white;
    --border-color-checked: var(--border-color);
    --checkmark-color: black;
    --checkmark-width: 2px;
    margin-top: 4px;
    z-index: 0;
}

.checkboxText {
    display: flex;
    align-items: center;
    margin-top: 2px;
}

.checkboxContainer {
    margin-bottom: 1rem;
    margin-right: 1.5rem;
}