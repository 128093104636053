.AntWaitingContainer{
    display: grid;
    height: 75%;
    width: 100% !important;
    justify-content: center;
    align-items: center;
    
}

@media only screen and (hover: none) and (pointer: coarse){

    .AntWaitingContainer{
        overflow-y: auto;
    }
    
 }

.AntWaitingContainer::-webkit-scrollbar {
    display: none;
  }

.AntWaitingContainer > img{
    margin: auto;
}

.waitingPersons{
    font-size: 0.8em;
    text-align: center;
    margin: 3px 0px 3px 0px;
    color: gray;
    font-family: var(--primary-font);
}