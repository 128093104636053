.newButtonsContainerDesktop{
    width: 100px;
    align-items: center;
    display: inline-flex;
    z-index: 9999;
    position: fixed;
    right: 13vw;
    top:17.5vh
}

.newButtonsContainerIframe{
    width: 26%;
    position: fixed;
    align-items: center;
    right: 30px;
    top: 20vh; 
    display: inline-flex;
    z-index: 9999;
    justify-content: flex-end;
}

.newButtonsContainerMobile{
    width: 30%;
    position: fixed;
    align-items: center;
    right: 30px;
    top: 13vh; 
    display: inline-flex;
    z-index: 9999;
    justify-content: flex-end;
}

.newButtonsContainerOnCallMobile{
    width: 30%;
    position: fixed;
    align-items: center;
    right: 30px;
    top: 1vh; 
    display: inline-flex;
    z-index: 9999;
    justify-content: flex-end;
}

.newButtonsContainerOnCallIframe{
    width: 100px;
    align-items: center;
    display: inline-flex;
    z-index: 9999;
    position: fixed;
    left: 75vw;
    top:1vh
}
.newButtonsContainerOnCallDesktop{
    width: 100px;
    align-items: center;
    display: inline-flex;
    z-index: 9999;
    position: fixed;
    right: 13vw;
    top:17.5vh
}
.amNewButtonsContainer{
    display: inline-grid;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    transition: 0.5s;
    width: 0px;
    left: 0px;
    overflow: hidden;
}

.hideNewButtonsDesktop{
    width: 35px;
    background-color: rgba(12, 12, 12, 0.39);
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    margin: auto;
}
.hideNewButtonsMobile{
    width: 55px;
    background-color: rgba(12, 12, 12, 0.39);
    height: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    margin: auto;
}
.hideNewButtonsIframe{
    width: 35px;
    background-color: rgba(12, 12, 12, 0.39);
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    margin: auto;
}
.navButtonsContainerMobile{
    width: 25%;
    position: fixed;
    align-items: center;
    left: 0px;
    display: inline-flex;
    z-index: 9999;
}
.navButtonsContainerIframe{
    width: 100px;
    position: absolute;
    align-items: center;
    display: inline-flex;
    z-index: 9999;
}
.navButtonsContainerDesktop{
    width: 100px;
    position: absolute;
    align-items: center;
    display: inline-flex;
    z-index: 9999;
}

.amNewButtonItemDesktop{
    height: 30px;
    width: 30px;
    border: .5px solid grey;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    margin: 5px 0px 5px 0px;
    transition: 0.2s;
    background-color: white;

}
.amNewButtonItemMobile{
    height: 50px;
    width: 50px;
    border: .5px solid grey;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    margin: 5px 0px 5px 0px;
    transition: 0.2s;
    background-color: white;

}

.amNewButtonItemIframe{
    height: 30px;
    width: 30px;
    border: .5px solid grey;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    margin: 5px 0px 5px 0px;
    transition: 0.2s;
    background-color: white !important;

}
.amNewButtonItemCloseDesktop{
    height: 40px;
    width: 40px;
    background: rgb(109 109 109);
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    margin: 5px 0px 5px 0px;
    transition: 0.2s;
}
.amNewButtonItemCloseIframe{
    height: 40px;
    width: 40px;
    background: rgb(109 109 109);
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    margin: 5px 0px 5px 0px;
    transition: 0.2s;
}
.amNewButtonItemCloseMobile{
    height: 60px;
    width: 60px;
    background: rgb(109 109 109);
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    margin: 5px 0px 5px 0px;
    transition: 0.2s;
}

.amNewButtonItemIframe{
    height: 40px;
    width: 40px;
    background: rgb(109 109 109);
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50%;
    margin: 5px 0px 5px 0px;
    transition: 0.2s;
}
.amButtonImg{
    height: 30px;
}

.callRotated{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.buttonSignalDesktop {
   height: 40px;
   width: 40px;
   position: fixed;
   right: 19vw;
   top: 19vh;
   border-radius: 50%;
   margin: auto;
   margin-bottom: 5px;
  }

  .buttonSignalMobile {
    height: 40px;
    width: 40px;
    position: fixed;
    right: 22vw;
    top: 4vh;
    border-radius: 50%;
    margin: auto;
    margin-bottom: 5px;
   }
  .parpadea {
    
    animation-name: parpadeo;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  
    -webkit-animation-name:parpadeo;
    -webkit-animation-duration: 1s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
  }
  
  @-moz-keyframes parpadeo{  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }
  
  @-webkit-keyframes parpadeo {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
     100% { opacity: 1.0; }
  }
  
  @keyframes parpadeo {  
    0% { opacity: 1.0; }
     50% { opacity: 0.0; }
    100% { opacity: 1.0; }
  }