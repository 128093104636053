.input {
    font-family: var(--secondary-font);
    --padding-top: 17px;
    --padding-bottom: 17px;
    --padding-end: 10px;
    font-size: 0.7rem;
    text-align: center;
    border-radius: 5px;
    box-shadow: none;
    z-index: 0;
}
