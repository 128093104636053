html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

.App-header {
    background-color: #222;
    height: 40px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
}

.App-header h1 {
    font-size: 16px;
    font-weight: 200;
}

.App-logo {
    height: 60%;
    width: auto;
}

.App-main {
    position: relative;
    width: 75vw;
    height: calc(75vw * .6);
    margin: 10px auto;
    border: 1px solid lightblue;
}

.App-control-container {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    background-color: transparent;
    width: 100%;
    position: fixed;
    bottom: 3%;
}

@media screen and (min-width: 800px)  {
    .App-control-container{
        position: sticky;
    }
}

.forceFixedPos {
    position: fixed !important;
}

@media only screen and (max-height: 780px) {
    .App-control-container {
        margin-top: 15px;
    }
} 
@media only screen and (max-height:660px) {
    .App-control-container {
        position: relative;
    }
} 

.App-control-container.hidden {
    display: none;
}

.App-control-container .ots-video-control {
    width: 6vh;
    min-width: 45px;
    height: 4vh;
    min-height: 45px;
    margin: 10px 0px !important;
    border: 2px solid white;
    border-radius: 50%;
    background-position: center;
    background-color: var(--primary-color);
    background-color: lightgrey;
    background-repeat: no-repeat;
    cursor: pointer;
}

.App-control-container .ots-video-control.audio {
    height: 20px;
    background-image: url(https://assets.tokbox.com/solutions/images/icon-mic.png);
}

.App-control-container .ots-video-control.audio.muted {
    background-image: url(https://assets.tokbox.com/solutions/images/icon-muted-mic.png);
}

.App-control-container .ots-video-control.video {
    background-image: url(https://assets.tokbox.com/solutions/images/icon-video.png);
}

.App-control-container .ots-video-control.video.muted {
    background-image: url(https://assets.tokbox.com/solutions/images/icon-no-video.png);
}

.App-control-container .ots-video-control.end-call {
    background-image: url(https://assets.tokbox.com/solutions/images/icon-hang-up.png);
    background-color: red;
}

.changeCameraIcon {
    height: 70%;
    width: 70%;
    color: white;
    margin-bottom: 3px;
}

.App-video-container {
    position: relative;
    width: 100%;
}

.App-mask {
    width: 100%;
    height: 100%;
    position: relative;
    color: white;
    background: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.App-mask .react-spinner {
    position: absolute;
}

.App-mask .message {
    font-weight: 200;
}

.App-mask .message.with-spinner {
    position: absolute;
    top: 55%;
}

.App-mask .message.button {
    border: 1px solid white;
    padding: 20px 40px;
    border-radius: 6px;
    background-color: white;
    color: gray;
    font-family: sans-serif;
    font-size: medium;
    width: 70%;
    box-shadow: 0px 0px 10px 0px black
}

.App-mask .message.button:focus:active,  .message.button:focus, .message.button:active {
  background-image: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.OT_publisher{
    border-radius: 5px;
}

.App-video-container .video-container {
    position: absolute;
    width: 100%;
    height: 100vh;
    display: flex;
}

.App-video-container .video-container.screenSharing {
    height: 100vh;
}

.App-video-container .video-container.small {
    top: 140px !important;
    right: 20px;
    position: fixed;
    width: 70px;
    height: 105px;
    max-width: 210px;
    object-fit: cover;
    border-radius: 20px;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 100;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.02), 0 0px 5px 3px rgba(0, 0, 0, 0.02);				
}

@media screen and (min-width: 800px)  {
    .App-video-container .video-container.small{
        top: 5px !important;
        position: absolute;
    }
}

.App-video-container .video-container.small-Oncall {
    top: 17px;
    right: 20px;
    position: fixed;
    width: 70px;
    height: 105px;
    max-width: 210px;
    object-fit: cover;
    border-radius: 20px;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 100;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.02), 0 0px 5px 3px rgba(0, 0, 0, 0.02);				
}

@media screen and (min-width: 800px)  {
    .App-video-container .video-container.small-Oncall{
        top: 5px !important;
        position: absolute;
    }
}

.App-video-container .video-container.small.left {
    left: 20px;
    border: 1px solid var(--primary-color);
}

.App-video-container .video-container.hidden {
    display: none;
}

.App-video-container .video-container.active-gt2 {
   display: flex;
   flex-direction: column;
   flex-wrap: wrap;
   height: 100%;
   width: 100%;
}

.App-video-container .video-container.active-gt2.hidden {
   display: none;
}

.App-video-container .video-container.active-gt2 .OT_subscriber {
  margin: 0;
  padding: 0;
  display: inline-block;
  font-size: 0;
  flex-basis: 50%;
  box-sizing: border-box;
  width: auto!important;
  height: auto!important;
}

.App-video-container .video-container.active-gt2.active-odd .OT_subscriber:first-child {
    flex-basis: 100%;
}

.App-chat-container {
    position: absolute;
    background-color: transparent !important;
    width: 100%;
    z-index: 100;
    .ots-hidden {
        z-index: 0;
    }
    .ots-text-chat {
        background-color: rgba(0, 0, 0, 0.5);
        border: 0px;
        margin-top: 0px;
        .ots-messages-holder {
            max-height: 53vh;
            height: 53vh;
            @media (max-width: 800px) {
                max-height: 71vh !important;
                height: 71vh !important;
            
        }
        
        }
        .ots-send-message-box {
            max-height: 60px;
            padding-right: 0px;
            background-color: white;
            height: 10vh;
            .active {
                background-color: var(--primary-color)
            }
        }
        .ots-icon-check {
            margin-top: -1px;
            max-height: 58px;
            border-left: 0px;
            width: 15%;
            height: 9.9vh;
        }
        .ots-send-message-box input[type="text"] {
            width: 72%;
            padding: 17px 25px 0;
        }
    }
}

.alertIcon {
    font-size: 100px;
    margin-bottom: 10px;
}


.cameraDisclaimerContainer {
    width: 60%;
    text-align: center;
    font-size: 14px;
    margin-top: 15px;
    position: absolute;
    bottom: 8%;
}

.camMainContainer{
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -10%;
    max-width: 80%;
}

.camWarningTitle{
    font-weight: 800;
    font-size: 24px;
}

.waitingScreenText{
    margin: auto;
    margin-top: 15px;
    text-align: center;
    width: 80%;
    font-weight: 600;
    font-size: 22;
    color: black;
}

.videoFullHeight{
    height: 100vh !important;
}

@media screen and (min-width: 800px) {
    .videoFullHeight{
        height: 500px !important;
    } 
}

  .mainV {
    width: 100%;
    height: 100%;
  }
  
  .videos__group {
    background-color: #171717;
    height: 100%;
    width: 100%;
  }
  
  #peer-video {
    height: 100vh;
  }
  
  #local-video {
    height: 120px;
    width: 80px;
    margin: 5px;
    border-radius: 7px;
  }
  
  .local-video-container{
    height: 13vh !important;
    width: 100%;
    align-items: right;
    position: fixed;
    height: 120px;
    z-index: 10;
    text-align: -webkit-right;
  }
  
  video {
    height: 100vh;
    width: 100%;
    object-fit: cover;
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
  }
  
  .options {
    justify-content: center;
    width: 100vw;
    top: 90vh;
    display: flex;
    position: absolute;
  }
  
  .options__button{
    width: 6vh;
    min-width: 45px;
    height: 6vh;
    min-height: 45px;
    margin: 10px 7px!important;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: #6d6d6d;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    color: #dedede;
  }
  
  @media only screen and (min-width: 500px) {
    .options__button{
      font-size: 3rem;
    }
  }
  
  #endCall {
    background-color: #f6484a;
  }
  
  #chat-window {
    position: absolute;
    height: 300px;
    top: 70vh;
    width: 100%;
    display: none;
    background: #6d6d6d66;
    border-radius: 15px;
    overflow: auto;
  }
  
  .main__chat_window {
    flex-grow: 1;
    overflow-y: scroll;
  }
  
  .main__message_container {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .main__message_container > input {
    height: 50px;
    flex: 1;
    font-size: 1rem;
    border-radius: 5px;
    padding-left: 20px;
    border: none;
  }
  
  .main__message_container > input:focus {
    outline: none;
  }
  
  .messages {
    display: flex;
    flex-direction: column;
    margin: 1.5rem;
  }
  
  .messageMe {
    text-align: right;
  }
  
  .messageMe > span {
    background-color: #eeeeee;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 10px 10px 0px 10px;
    margin-left: 10px;
    display: inline-flex
  }
  
  .messageOther {
    display: flex;
  }
  
  .messageOther > span {
    background-color: #eeeeee;
    margin: 1rem 0;
    padding: 1rem;
    border-radius: 0px 10px 10px 10px;
    margin-left: 10px;
  }
  