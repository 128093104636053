@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap');
:root {
  /* Set the background of the entire app */
  --primary-font:'Source Sans Pro', sans-serif;
  --secondary-font: 'Open Sans', sans-serif;
  --primary-color: #007DDE;
  --secondary-color: #007DDE;
  --cancel-color: #ef345f;
  --ion-color-danger: #ea4379;
  --primary-color-darken: hsl(206, 100%, 25%);
  --wrapper-top: 0vh;
  --secondary-color-lighten: hsl(206, 100%, 110%);
  --logo: url("/img/square_livesupport_logo.png");
  --ion-background-color: white;
  --ion-color-header: var(--primary-color);
  --ion-color-header-contrast: #FFFFFF;
  --ion-color-footer: var(--primary-color);
  --ion-color-footer-contrast: #FFFFFF;
  --img-header: url('https://images.pexels.com/photos/4240608/pexels-photo-4240608.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260');

  /* Set the font family of the entire app */
  --ion-font-family: /*-apple-system, BlinkMacSystemFont,*/  "Open Sans", sans-serif;
}
.App{
  height: 100%;
}
body{
  min-height: 100%;
}
html{
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}

h1 {
  
  font-size: 2em;
  text-align: center;
  font-weight: bold;
  color: var(--secondary-color)
}

.h1_Feedback {
  font-size: 1.6em !important;
  text-align: center;
  font-weight: bold;
  color: var(--secondary-color)
}
.h1FeddbackIframe {
  font-size: 1em !important;
  text-align: center;
  font-weight: bold;
  color: var(--secondary-color);
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}

h2 {
  font-family: var(--primary-font);
  font-size: 1.2em;
  text-align: center;
  font-weight: 600;
  color: var(--secondary-color)
}

.title{
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 0.9rem !important;
}

h3 {
  color: #3f455d;
  font-size: 0.8rem;
  margin-top: 15px;
  margin-bottom: 1.5rem;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
}

h4 {
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.5em;
  text-align: center;
}
  
/* Set text color of the entire app for iOS only */
.ios {
  --ion-text-color: #000;
}

/* Set text color of the entire app for Material Design only */
.md {
  --ion-text-color: #222;
}

// Color classes
.ion-color-header {
  --ion-color-base: var(--ion-color-header);
  // --ion-color-base-rgb: var(--ion-color-header-rgb);
  --ion-color-contrast: var(--ion-color-header-contrast);
  // --ion-color-contrast-rgb: var(--ion-color-header-contrast-rgb);
  // --ion-color-shade: var(--ion-color-header-shade);
  // --ion-color-tint: var(--ion-color-header-tint);
}
.ion-color-footer {
  --ion-color-base: var(--ion-color-footer);
  // --ion-color-base-rgb: var(--ion-color-footer-rgb);
  --ion-color-contrast: var(--ion-color-footer-contrast);
  // --ion-color-contrast-rgb: var(--ion-color-footer-contrast-rgb);
  // --ion-color-shade: var(--ion-color-footer-shade);
  // --ion-color-tint: var(--ion-color-footer-tint);
}

.centerText {
  text-align: center;
}

.footer {
  min-height: 4vh;
}

.footerImage {
  width: 90px;
  display: inline-block;
}

.footerDown{
  position: absolute;
  bottom: 0px;
}

.footerFree{
  position: static;
}

.flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:-85px;
}

.smallMarginVertical {
  margin-top: 10px;
  margin-bottom: 10px;
}

.smallMarginHorizontal {
  margin-left: 7%;
  margin-right: 7%;
  div h1 img {
    height: 100% !important;
  }
}

.marginToHeader {
  margin-top: 20px;
}

.marginToHeaderIframe {
  margin-top: 1px;
}

.paragraphStart {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px;
}

.camOptional { 
  max-width: 70%;
  margin: auto;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px;
}

.paragraphEnd {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 20px;
}
.paragraphEndIframe {
  text-align: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.arrowIconAdjust {
  margin-right: 10px;
  margin-bottom: 2px;
}

.inputWrapper{
  margin-top: 25px;
}

.centerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.justifyCenter {
  justify-content: center;
}

.disclaimer {
  margin: auto;
  display: flex;
  justify-content: space-around;
  color: lighten($color: black, $amount: 50%) ;
  font-size: 0.8rem;
  width: 80%;
}

.smileIcon {
  height: 60px;
  width: 60px;
  display: inline-block
}

.progress {
  margin: auto;
  margin-top: 5rem;
  background-color: #d0d1d9;
  border: 2px solid #d0d1d9;
  border-radius: 25px;
  width: 70%;
  height: 0.85em;
}

.progress_bar {
  position: relative;
  border-radius: 25px;
  height: 100%;
  background: #ffaa00;
  background: linear-gradient( 90deg, rgba(255, 170, 0, 0.85) 40%, rgba(255, 102, 0, 0.85) 100% );
  animation-name: infinite;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

.runningAdjust {
  position: absolute;
  color: #3f455d;
  font-size: 2rem;
  right: 0;
  top: -400%;
  font-weight: 900;
  margin-right: 8px;
}

@keyframes infinite {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.disabledSmallParagraph {
  color: #999999;
  font-size: 0.7rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 1.2em;
  letter-spacing: 0.5px;
  text-align: center;
}

.scoreContainer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  width: 100%;
}

.imageAndTextContainer {
  display: flex;
  flex-direction: column;
  align-Items: center;
  padding: 5px;
}

.imageAndTextMargin {
  color: gray;
  font-size: 0.7rem;
  margin-top: 10px;
  margin-bottom: 1.5rem;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.shareContainer {
  margin-bottom: 40px;
}

.useFlexRow {
  display: flex;
  flex-direction: row;
}

.useFlexCenter {
  justify-content: center;
}

.fixedToBottom {
  position: absolute;
  bottom: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.endCallButton {
  width: 50%;
  z-index: 100;
  background-color: transparent;
}

.questionTextArea {
  margin-top: 20px
}

.columnContainerWithFlex {
  display: flex;
  flex-direction: column;
}

.alignSelfCenter {
  align-self: center;
}

.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 200ms linear 150ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0;
    transition: opacity 150ms linear;
}

.fullHeight {
  height: 100vh !important;
}

.appointmentScheduleContainer {
    display: flex;
    justify-content: center;
    max-width: 500px;
    font-size: 0.8rem;
    height: 4rem;
    padding: 1rem !important;
}

.appointmentScheduleTextContainer {
  justify-content: space-around;
  margin-left: 20px;
};

.errorText {
  color: red;
  text-align: center;
}

.tycText {
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: .8rem;
  font-weight: 600;
  text-decoration: none;
  color: #3b93d2;
}

.applyCustomBoxStyle {
  background: white !important;
  color: #353535 !important;
  border-radius: 5px;
  border: 1.5px solid rgba(221, 221, 221, 0.612);
  padding:  1.5rem;
  width: 100%;
  font-weight: 600;
  margin-bottom: 1rem;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.02), 0 0px 5px 3px rgba(0, 0, 0, 0.02);
  
  input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px rgb(255, 255, 255) inset !important;
} 
 
}

.fabOnVideo {
  --background: #53B216;
  --border-radius: 15px;
  --box-shadow: 0px 3px 6px 4px rgba(0, 0, 0, 0.3);
  font-size: 1rem;
  font-weight: 600;
  margin-top: 30px;
  margin-left: 30px;
  width: 9rem !important;
  height: 3rem !important;
}

.imgFrame p{
  border-radius: 10px;
}

.imgFrame{
  border-radius: 10px;
}

.hide {
  display: none;
}

.twoButtons {
  display: flex;
  justify-content: space-around;
}

.whiteSpaceNormal {
  white-space: normal !important;
}

ion-toast::part(message) { 
  text-align: left !important;
  font-size: .75em !important;
  text-transform: inherit !important;
  font-weight: 600;
  letter-spacing: .2px;
  margin-left: -5%;
}

.qr {
  height: 150px;
}

.iframe{
  border: var(--secondary-color) solid 2px;
  z-index: 999;
  width: 100%;
  border: none;
  display: contents;
}

.wrapper{
  overflow-x: visible;
}

@media only screen and (min-width: 650px) {
  .wrapper{
    overflow: visible
  }
}

@media only screen and (min-width: 650px) {
  .wrapper{
    overflow: visible
  }
}

@media only screen and (min-width: 800px) {
  h3 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .native-input.sc-ion-input-md {
    max-height: 30px;
  }

  .react-tel-input .form-control{
    max-height: 30px;
  }
  .marginToHeader{
    margin-top: 5px;
  }
}