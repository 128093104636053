.AMimportants {
    color: gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 25px;
    margin-bottom: 25px;
  }

.AMimportants > p {
    font-size: 0.8rem;
    margin: 2px;
    font-family: var(--primary-font);
}

.AMpermitsContainer{
    display: grid;
    height: 75%;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: auto;
}

.AMpermitsContainer > img{
    margin: auto;
}

.AMpermissionsImg{
    border-radius: 15px;
    border: solid 2px var(--primary-color);
    margin: auto;
}

.AMAlertLockImg{
    height: 16px;
    width: 16px;
    background-color: white;
}